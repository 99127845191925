import React from 'react'
import "./AdvantageScreen.css"
const AdvantageScreen = () => {
  return (
<div className="content__section">

  
      
</div>
  )
}

export default AdvantageScreen