import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {

  const navigate = useNavigate();

  const handleLogin = () =>{
	navigate('/',{
    replace:true
  });
    
  }
  return (
    <div className="content__section container mt-5">
        <h1>LoginScreen</h1>
        <hr/>

        <button className="btn btn-primary"
        onClick={handleLogin}>
          Login
          </button>

    </div>
  )
}

export default LoginScreen