import * as React from "react"

const RepairsIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16.933 16.933"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M15.226.557 12.582 1.88a.265.265 0 0 0-.147.237v.95L6.087 9.413l-.341-.341a.26.26 0 0 0-.375 0l-.793.795c-.174.174-.045.331.034.41l1.894 1.894-3.295 3.295c-.528.529-1.329.546-1.809.067-.48-.48-.462-1.28.067-1.81l2.566-2.567c.29-.248-.125-.663-.373-.374L1.094 13.35c-.717.718-.76 1.864-.067 2.557.693.693 1.84.65 2.557-.067l4.278-4.277a.265.265 0 0 0 0-.373l-.343-.343 6.348-6.348h.95c.1 0 .191-.058.236-.147l1.324-2.647a.265.265 0 0 0-.05-.304l-.794-.795a.263.263 0 0 0-.307-.049zM2.381.53a.265.265 0 0 0-.187.451L3.97 2.755v.575l-.639.638h-.575L.981 2.194a.265.265 0 0 0-.452.187v2.117c0 .07.028.137.078.187L1.93 6.008c.05.05.117.077.187.077h2.007L6.209 8.17c.25.26.634-.125.374-.374L4.42 5.633a.265.265 0 0 0-.187-.077H2.226L1.058 4.388V3.02l1.4 1.4c.05.05.118.078.188.078h.794c.07 0 .137-.028.187-.078l.793-.794a.265.265 0 0 0 .078-.187v-.794a.265.265 0 0 0-.078-.187l-1.4-1.4h1.368l1.168 1.168v2.007c0 .07.028.137.078.187l2.162 2.163c.25.26.634-.125.375-.374L6.085 4.123V2.116a.265.265 0 0 0-.077-.187L4.685.606A.265.265 0 0 0 4.498.53zm12.912.587.525.523-1.166 2.33h-.894a.265.265 0 0 0-.188.076l-6.425 6.427-.683-.683 6.425-6.427a.265.265 0 0 0 .078-.188V2.28zm-4.757 7.567a.265.265 0 0 0-.185.455l2.162 2.161c.05.05.117.078.187.078h2.007l1.168 1.168v1.368l-1.4-1.4a.265.265 0 0 0-.187-.078h-.794a.265.265 0 0 0-.187.078l-.794.793a.265.265 0 0 0-.078.187v.794c0 .07.028.138.078.187l1.4 1.4h-1.368l-1.168-1.167V12.7a.265.265 0 0 0-.077-.187L9.138 10.35c-.25-.26-.634.125-.374.374l2.084 2.084v2.007c0 .07.028.138.077.187l1.323 1.323c.05.05.117.078.187.078h2.117a.265.265 0 0 0 .187-.452l-1.774-1.774v-.575l.638-.639h.575l1.775 1.775a.265.265 0 0 0 .451-.187v-2.117a.265.265 0 0 0-.077-.187l-1.323-1.323a.265.265 0 0 0-.187-.077H12.81l-2.085-2.085a.265.265 0 0 0-.19-.08zm-4.977.952L7.3 11.377l-.42.42-1.744-1.742z"
      data-original="#000000"
      xmlns="http://www.w3.org/2000/svg"
    />
  </svg>
)

export default RepairsIcon
