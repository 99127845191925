import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import sell1 from '../../assets/habilities/sell1.png';
import sell2 from '../../assets/habilities/sell2.png';
import sell3 from '../../assets/habilities/sell3.png';

import Button from "./Button";

export default function Habilities() {
  const data = [
    {
      image: sell1,
      title: "1. Presentación",
      description:
        "Primero coordinamos una fase inicial de acercamiento con el cliente para conocernos y que nos planteé sus necesidades y espectativas. para poder brindarle lo mejor de nuestros servicios.",
      buttonText: "Ver Servicios",
    },
    {
      image: sell2,
      title: "2. Planteamiento",
      description:
        " Una vez que analizamos las necesidades, con base en nuestra experiencia le enviamos al cliente las acciones sugeridas en la empresa o hogar.",
      buttonText: "Contactar",
    },
    {
      image: sell3,
      title: "3. Cotización e implementación",
      description:
        "Le enviamos una cotización al cliente y posteriormente se establece un cronograma de implementación donde se avanza conforme a las necesidades del cliente.",
      buttonText: "Empezar",
    },
  ];
  return (
    <Section>
      <div className="title">
        {/* <h2>Adquiera nuestros planes y soluciones</h2> */}
        {/* <h3>de soporte y mantenimiento a la medida.</h3> */}
        <h3>¿Cómo trabajamos?</h3>
      
      </div>
      <div className="container">
        <div className="ellipse"></div>
        <div className="content">
          {data.map(({ image, title, description, buttonText }, index) => {
            return (
              <div className="hability__container" key={index}>
                <div className="hability__container-image">
                  <img src={image} alt="modalidad de trabajo" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>

                <Button text={buttonText} blue={index === 1} />
              </div>
            );
          })}
          
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  margin: 3rem 6rem;
  margin-bottom: 5rem;
  font-family: "Inter", sans-serif;
  .title {
    text-align: center;
 
    h3 {
      font-size: 2.6rem;
      margin-bottom: 4rem;
    }
  }
  .container {
    /* background-color: #232835; */
    border-radius: 50px;
      background: #f7f7f7;
      /* box-shadow:  20px 20px 60px #cecece,
             -20px -20px 60px #f2f2f2; */
             box-shadow: 10px 10px 30px #cecece, -10px -10px 25px #f2f2f2;
    padding: 4rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    .ellipse {
      height: 30rem;
      width: 30rem;
      background-color: #0f10117b;
      border-radius: 100%;
      filter: blur(2000px);
      opacity: 0.5;
      position: absolute;
      bottom: -30%;
      left: -10%;
      z-index: 0;
    }
    .content {
      display: flex;
      gap: 2.5rem;
      .hability__container {
        background-color: #fff;
        z-index: 0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 1rem;
        .hability__container-image {
          background-color: #2d69fd;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
          width: max-content;
          border-radius: 3rem;
          img {
            height: 2.5rem;
          }
        }
        h3 {
          color: #111;
          /* font-weight: 100; */
        }
        p {
          color: #111;
          margin-bottom: 1rem;
        }
        button {
        }

        @media screen and (max-width: 450px){
          padding: 1rem;
        }
      }
    }
  }
  @media screen and (min-width: 200px) and (max-width: 1080px) {
    margin: 0;
    .title {
   
      h3 {
        padding-top: 4rem;
        font-size: 2rem;
      }
    }
    .container {
      padding: 2rem;
      .content {
        flex-direction: column;
        gap: 2rem;
      }
    }
  }


  @media screen and (min-width: 0px) and (max-width: 385px) {
    .container {
   
      box-shadow: 5px 5px 15px #cecece, -5px -5px 25px #f2f2f2;
    }
  }
`;
