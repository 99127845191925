import * as React from "react"

const MaintenceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg" data-name="Maintenance tools">
      <path
        d="M59.414 45.9a2.035 2.035 0 0 0-2.726-.732l-10.072 5.814a3.982 3.982 0 0 1-3.965 4.318 4.043 4.043 0 0 1-1.042-.138l-11.925-3.196a1 1 0 0 1 .517-1.932l11.926 3.2a2 2 0 1 0 1.035-3.863l-14.92-4a5.025 5.025 0 0 0-2.508-.021L20 46.781v10.6l2.181 1.09a5.027 5.027 0 0 0 2.235.529h14.977a5 5 0 0 0 2.5-.67l16.8-9.7a2.008 2.008 0 0 0 .721-2.73zM4 46h6v12H4zM12 44h6v16h-6zM33.1 11.687a.972.972 0 0 1-.187-.259l-1.278-2.553L26.2 5.86l-.34.34 3.015 5.431 2.554 1.281a.99.99 0 0 1 .258.187l6.776 6.776 1.416-1.415z"
        data-original="#000000"
      />
      <path
        d="M51.562 18.317a7.006 7.006 0 0 0 7.938-8.16l-4.065 4.065a1 1 0 0 1-.9.273L51 13.788a1 1 0 0 1-.788-.788l-.707-3.536a1 1 0 0 1 .273-.9L53.844 4.5a7.005 7.005 0 0 0-8.16 7.941 5.1 5.1 0 0 1-1.379 4.426l-7.439 7.438a5.167 5.167 0 0 1-4.428 1.378 7.006 7.006 0 0 0-7.938 8.16l4.065-4.065a1 1 0 0 1 .9-.273l3.535.707a1 1 0 0 1 .784.784l.707 3.536a1 1 0 0 1-.273.9L30.156 39.5a7.005 7.005 0 0 0 8.16-7.941 5.1 5.1 0 0 1 1.384-4.426l7.434-7.433a5.164 5.164 0 0 1 4.428-1.383z"
        data-original="#000000"
      />
      <path
        d="m45.827 31.486 5.658-5.657a1 1 0 0 0-1.414-1.415l-5.657 5.657a1 1 0 0 0 0 1.414 1.023 1.023 0 0 0 1.413.001zM57.971 37.971a3 3 0 0 0 0-4.243l-5.779-5.778-4.243 4.242 5.779 5.779a3.005 3.005 0 0 0 4.243 0zM44.114 25.54l1.416-1.417 1 1-1.416 1.416z"
        data-original="#000000"
      />
    </g>
  </svg>
)

export default MaintenceIcon
