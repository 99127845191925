import React from 'react';
import ReactDOM from 'react-dom/client';
import { HeroesApp } from './HeroesApp';
import index from './index.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <HeroesApp />
</>
);


