import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HostingIcon from "../ui/icons/HostingIcon";
import MaintenceIcon from "../ui/icons/MaintenceIcon";
import RedesIcon from "../ui/icons/RedesIcon";
import RepairsIcon from "../ui/icons/RepairsIcon";
import ServerIcon from "../ui/icons/ServerIcon";
import SupportIcon from "../ui/icons/SupportIcon";
import { ThemeProvider } from "styled-components";

import Button from "./Button";

export default function ServicesSectionRoom() {


  const navigate = useNavigate();

  const handleRedirect = () =>{
	navigate('/services');
  }    

  return (
    <Section>
      <div className="title">
        <h3>¿Cuáles son nuestros servicios?</h3>
      </div>
      <div className="container__services">
        <div className="ellipse"></div>
        <div className="container__box">
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <SupportIcon /> 
            <h5>Soporte técnico</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <RedesIcon />
            <h5>Redes</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <MaintenceIcon />
            <h5>Mantenimiento</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <ServerIcon />
            <h5>Servidores</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <HostingIcon />
            <h5>Hosting Web</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <i className="fi fi-rs-shopping-cart"></i>
            <h5>Venta</h5>
            <h5>de</h5>
            <h5>Articulos</h5>
          </div>
          <div className="box animate__animated animate__backInLeft" onClick={handleRedirect}>
            <RepairsIcon />
            <h5>Reparaciones</h5>
          </div>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  margin: 3rem 6rem;
  margin-bottom: 5rem;
  font-family: "Inter", sans-serif;
  .title {
    text-align: center;
    h2 {
      padding-top: 2rem;
      font-size: 2.6rem;
    }
    h3 {
      font-size: 2.6rem;
      margin-bottom: 4rem;
    }
  }
  .container__services {
    /* background-color: #232835; */
    border-radius: 50px;
    background: #f7f7f7;
   /*  box-shadow: 20px 20px 60px #cecece, -15px -15px 60px #f2f2f2; */
    box-shadow: 10px 10px 30px #cecece, -10px -10px 25px #f2f2f2;
    padding: 5rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }

  .container__box {
    max-width: 1100px;
    margin: auto;
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .box {
      width: 15rem;
      height: 200px;
      background-color: #fff;
      margin: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      transition: all 200ms ease;

      i {
        font-size: 2rem;
        color: #1e72e5;
      }
      svg {
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
        fill: #1e72e5;
      }

      @media screen and (max-width: 492px){
      width: 12rem; 
      }

    @media screen and (max-width: 380px){
      width: 11.2rem;
    }

    @media screen and (max-width: 355px){
        width: 100%;
    }
    
    }

    .box:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 30px -15px rgba(0, 0, 0, 0.5);
      /* background: #000000; */
      z-index: 1;
      i {
        /* color: #fff; */
      }
      svg {
        /* fill: #fff; */
      }
      h5{
        /* color: #fff; */
        /* opacity: 0;   */
      }
    }

    @media screen and (max-width: 645px){
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 1px;
    }

    
    @media screen and (max-width: 355px){
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    
  }

  @media screen and (min-width: 200px) and (max-width: 1080px) {
    margin: 0;
    .title {
      h3 {
        padding-top: 4.5rem;
        font-size: 2rem;
      }
    }
    .container {
      padding: 2rem;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 385px) {
    .container__services {
      padding: 0rem;
      box-shadow: 10px 10px 30px #cecece, -10px -10px 30px #f2f2f2;
    }
  }




`;
