import * as React from "react"
const ExitIcon = (props) => (
  <svg className='icon'
    style={{
      transform: "scale(1.5)",
      strokeWidth: 1,
    }}
    width={18}
    height={18}
    fill="#111"
    viewBox="-6 -6 26 26"
    {...props}
  >
    <path d="M12.12.36 7 5.48 1.88.36.44 1.88 5.48 7 .44 12.12l1.44 1.44L7 8.52l5.12 5.04 1.52-1.44L8.52 7l5.12-5.12z" />
  </svg>
)

export default ExitIcon;
