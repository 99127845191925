import React from 'react'
import {BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import DcScreen from '../components/dc/DcScreen.js';
// import DcScreen from '../components/dc/DcScreen.jsx'
import LoginScreen from '../components/login/LoginScreen.jsx'
import MainScreen from '../components/main/MainScreen.jsx';
import VideoScreen from '../components/main/VideoSection.jsx';
import MarvelScreen from '../components/marvel/MarvelScreen.js';
// import DoctorsScreen  from '../components/doctors/DoctorsScreen.jsx'
import SearchScreen from '../components/search/SearchScreen.jsx'
import ServicesScreen from '../components/services/ServicesScreen.jsx';
import { Navbar } from '../components/ui/NavBar.js';
import StyledNav from '../components/ui/StyledNav.jsx';
import DashboardRoutes from './DashboardRoutes.js';



const AppRouter = () => {
    return (
        <BrowserRouter>

         <Routes>

            <Route path="/*" element={<MainScreen/>} />
            {/* <Route path="/AdnetApp" element={<MainScreen/>} /> */}
            <Route path="/login" element={<LoginScreen/>} />
            <Route path="/services" element={<ServicesScreen/>} />
            {/* <Route path="/*" element={<DashboardRoutes/>} /> */}

          </Routes>
 
        </BrowserRouter>
    )
}

export default AppRouter