import { NavLink } from "react-router-dom";
import LineIcon from "../ui/icons/LineIcon";
import shortid from 'shortid';
import { EffectCube,EffectFade, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-cube";
import "swiper/css/effect-fade";

import "./servicesItem.css";




// title, description, arrayofLinks
const ServiceItem = ({...data}) => {



    
    const {description,links,title,images} = data.data;
    
  return (
    <div className="service__container">
      <div className="service__logo-container"></div>
      <div className="slider__container">
        
        <Swiper modules={[EffectCube,Autoplay,EffectFade]}
         effect={"fade"}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
     spaceBetween={20}
     slidesPerView={1}
    
      key={shortid.generate()} className="slider__container">

{

images.map((image)=>{
              
  if(image){
   return(
  <SwiperSlide  key={shortid.generate()}>
     <img src={image} alt="Servicio" className="service__image" key={shortid.generate()}/>
  </SwiperSlide>
   );
  }
})

}

        </Swiper>
      
      </div>
    
<div className="service__details">
    
          <h3 className="service__title">
           {title}
          </h3>
    
          <h4 className="service__description">
          {description}
          </h4>
</div>

      <div className="service__line-container">
      <LineIcon height="30" width="45" color="#2d69fd" />
      </div>

      <div className="service__links-container">
        <ul className="service__ul">
         
        {
            links.map((link)=>{
              
               if(link.address!==''){
                return(
                    <li className="service__list-item"  key={shortid.generate()}>
               <NavLink className="service__link-active" to={link.address}>
                 {link.name}
                </NavLink>
                  </li> 
                )

               }else{

                return(
                    <li className="service__list-item" key={shortid.generate()}>
               <NavLink className="service__link" to="/" onClick={(e)=>{
                 e.preventDefault();
               }}>
                {link.name}
                </NavLink>
                  </li> 
                )                

               }

               
            })
        }
        </ul>
      </div>
    </div>
  );
};

export default ServiceItem;
