

const AdnetMinIcon = ({letterscolor = "white"}) => (
  <svg
    width={285}
    height={37.672}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
   
  >
    <defs>
      <clipPath id="AdnetMini">
        <use xlinkHref="#AdnetMiniA" />
      </clipPath>
      <path d="M0 0h285v37.672H0V0Z" id="AdnetMiniA" />
    </defs>
    <path d="M0 0h285v37.672H0V0Z" fill="none" />
    <g clipPath="url(#AdnetMini)" fillRule="evenodd">
      <path
        d="m21.958 3.051-6.421 11.282h6.193l3.172-5.395 6.785 12.867H17.34l3.018-5.134h-6.152l-10.243 18h6.98l3.586-6.948h20.26l3.297 6.947h5.816v-4.803L28.003 3.105l-6.045-.054ZM42.738 6.536l1.94 2.058-.002 14.622h6.205v-9.475l-1.55-2.402h15.123s8.432-1.373 9.305 8.235c.873 9.607-9.693 9.607-9.693 9.607H49.33l1.55-2.745v-.883h-6.204l.002 9.117h21.326s14.453-.504 13.959-15.44C79.478 4.564 62.902 6.537 62.902 6.537H42.738Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="m78.799 2.761 2.714 2.745V34.67h6.593V13.054l25.204 21.274h4.652V2.761h-6.203v21.96L86.167 2.76H78.8ZM118.738 8.251V34.33h27.53v-5.49h-21.326v-6.177h12.409V17.86h-12.797v-5.49h18.226V8.251h-24.042Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="M143.396 8.251v4.118h11.633v10.847h6.205V12.37h12.795V8.251h-30.633Zm11.633 17.303v4.656l-2.714 4.118h8.918v-8.775h-6.204ZM199.372 17.323h10.671v3.01h-10.671v-3.01Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="M220.046 21.222h10.672v3.01h-10.672v-3.01ZM226.049 14.916h10.671v3.01h-10.671v-3.01Z"
        fill="#666"
      />
      <path
        d="M266.732 14.916h15.339v3.01h-15.339v-3.01ZM247.392 8.894h10.67v3.01h-10.67v-3.01ZM247.392 20.333h19.34v3.012h-19.34v-3.012Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
    </g>
  </svg>
)

export default AdnetMinIcon
