import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import HamburgerIcon from "./icons/HamburgerIcon";
import ExitIcon from "./icons/ExitIcon";
// import MedicalAdvisor from "./icons/MedicalAdvisor";
// import AvengersIcon from "./icons/AvengersIcon";
import { ThemeProvider } from "styled-components";
import { Theme } from "../../themes/Theme.styled";
import AdnetIcon from "./icons/AdnetIcon";
import AdnetMinIcon from "./icons/AdnetMinIcon";



const Container = styled.div`

  width: 100%;
  height: 60px;
  z-index: 2;
  transition: background 0.5s ease-in-out 0s;
  background-color: ${({ scrolled, theme,  transparency }) =>{
    if(scrolled === 'true'){
      return theme.bgColorSecondary;
    }else if(transparency === 'true'){
      return theme.bgColorTransparent;
    }else if(transparency === 'false'){
      return theme.bgColorPrimary;
    }
    else{
      return theme.bgColorPrimary;
    }
  }
};
  box-shadow: rgb(51 51 51 / 10%) 1px 1px 2px;
  position: fixed;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
`;

const LogoContainer = styled.div`
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  svg{
    
    @media screen and (max-width: 406px) {
      width: 228px;
      height: 45px;
      
     }

  }

  

  p {
    font-family: "Inter", sans-serif;
    font-weight: bold;
    margin: 0;
    color: ${({ scrolled, theme }) =>
      scrolled==="true" ? theme.logoTextSecondary : theme.logoTextPrimary};
  }

  

  @media screen and (max-width: 1028px) {
    margin-bottom: 0px;
  }
`;

const Menu = styled.ul`
  height: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
  margin-right: 3.5rem;
  @media screen and (max-width: 1028px) {
    background-color: ${({ theme }) => theme.bgColorThird};
    position: absolute;
    top: 60px;
    left: ${({ open }) => (open ? "0" : "-100%")};
    width: 100%;
    height: 92vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all ease;
    margin-right: 0;
  }
`;

const MenuItem = styled.li`
  height: 100%;

  @media screen and (max-width: 1028px) {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0.5rem 2.5rem;
    color: ${({ scrolled, theme }) =>
      scrolled==="true" ? theme.linkSecondary : theme.linkPrimary};
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    transition: 0.5s all ease;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: bold;

    @media screen and (max-width: 1028px) {
      color: ${({ theme }) => theme.mobileLinkColor};
    }
  }

  .activa {
    color: ${({ scrolled, theme }) =>
      scrolled==="true" ? theme.activeSecondary : theme.activePrimary};


@media screen and (max-width: 1028px) {
      color: ${({ theme }) => theme.activeSecondary};
    
  }

  }

  
`;

const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1028px) {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      fill: ${({ scrolled, theme }) =>
        scrolled==="true" ? theme.iconSecondary : theme.iconPrimary};
      margin-right: 0.5rem;
    }
  }
`;

const NavButton = styled(NavLink)`
  color: rgb(255, 255, 255);
  min-width: 70px;
  width: auto;
  background: ${({ scrolled, theme }) =>
    scrolled ==="true" ? theme.buttonBgSecondary : theme.buttonBgPrimary};
  border: 1px solid
    ${({ scrolled, theme }) =>
      scrolled==="true" ? theme.buttonBorderSecondary : theme.buttonBorderPrimary};
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: none;
  padding: 7px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out 0s,
    border-color 0.1s ease-in-out 0s;

  &:hover {
    color: rgb(255, 255, 255);
  }

  @media screen and (max-width: 1028px) {
    background: ${({ theme }) => theme.buttonBgSecondary};
    border: 1px solid ${({ theme }) => theme.buttonBorderSecondary};
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;



const StyledNav = ({transparent}) => {

  const [smallScreen, setSmallScreen] = useState(true);

    const [windowDimention, detectHW] = useState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  
    const detectSize = () => {
      detectHW({winWidth: window.innerWidth,winHeight: window.innerHeight,})
    }
  
    useEffect(() => {
      window.addEventListener('resize', detectSize)
            // console.log(windowDimention.winWidth);
          windowDimention.winWidth <= 454 ? setSmallScreen(true) : setSmallScreen(false); 
          // console.log(smallScreen);
      return () => {
        window.removeEventListener('resize', detectSize)
      }
    }, [windowDimention])

  
    const [showMobileMenu, setMobileMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  


  const toggleMobileMenu = () => {
    setMobileMenu(!showMobileMenu);
  }

  const changeStylesOnScroll = () => {
    if (window.scrollY >= 80) {
      
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  

  window.addEventListener("scroll", changeStylesOnScroll);

  const checkScroll = () => navbar ? "true" : undefined;

 

  return (
    
    <ThemeProvider theme={Theme}>
      
      <Container scrolled={checkScroll()} transparency={transparent}>
        <Wrapper>
          <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
            <LogoContainer scrolled={checkScroll()}>
              {/* <AvengersIcon fill="#D10000" /> */}
              {/* <MedicalAdvisor letterscolor={navbar ? "#111" : "#fff"}/> */}
              {/* <img src="/assets/adnet.png" alt="Adnet"/> */}
              {/* <p>StrongHold</p> */}
              {/* <img src="https://www.adnetcr.com/wp-content/uploads/2018/05/ADNETLOGO2015.fw_.png" alt="adnet" /> */}
              {
                smallScreen ? <AdnetMinIcon className="AdnetIcon" letterscolor={navbar ? "black" : "white"}/> : <AdnetIcon className="AdnetIcon" letterscolor={navbar ? "black" : "white"}/>
              }
    
            </LogoContainer>

            <MobileIcon
              onClick={toggleMobileMenu}
              scrolled={checkScroll()}
            >
              {showMobileMenu ? (
                <ExitIcon width="30" height="30" />
              ) : (
                <HamburgerIcon />
              )}
            </MobileIcon>

            <Menu open={showMobileMenu}>
              <MenuItem scrolled={checkScroll()}>
                <NavLink 
                  className={
                    ({ isActive }) =>{
                      if(isActive){
                        return("link" + " activa");
                      }else{
                        return("link");
                      }
                    }
                   
                  }
                  to="/"
                  onClick={toggleMobileMenu}
                >
                  Inicio
                </NavLink>
              </MenuItem>

              <MenuItem scrolled={checkScroll()}>
                <NavLink 
                  className={
                    ({ isActive }) =>{
                      if(isActive){
                        return("link" + " activa");
                      }else{
                        return("link");
                      }
                    }
                   
                  }
                  to="/search"
                  onClick={toggleMobileMenu}
                >
                  Buscar
                </NavLink>
              </MenuItem>

              <MenuItem scrolled={checkScroll()}>
                <NavLink
                  className={({ isActive }) =>
                    "link" + (isActive ? " activa" : "")
                  }
                  to="/plans"
                  onClick={toggleMobileMenu}
                >
                  Planes
                </NavLink>
              </MenuItem>
              <MenuItem scrolled={checkScroll()}>
                <NavLink
                  className={({ isActive }) =>
                    "link" + (isActive ? " activa" : "")
                  }
                  to="/services"
                  onClick={toggleMobileMenu}>
                  Servicios
                </NavLink>
              </MenuItem>


              {/* <CtaContainer>
                <NavButton
                  to="/login"
                  onClick={toggleMobileMenu}
                  scrolled={checkScroll()}
                >
                  <span>Login</span>
                </NavButton>
              </CtaContainer> */}
            </Menu>
          </IconContext.Provider>
        </Wrapper>
      </Container>

    </ThemeProvider>
  );
};

export default StyledNav;
