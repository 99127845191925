export const Theme = {

    
    bgColorPrimary:'#111',
    bgColorSecondary:'#fff',
    bgColorTransparent:'transparent',
    bgColorThird:'#111',
    activePrimary:'rgb(255, 255, 255)',
    activeSecondary:'rgb(0, 83, 247)',
    linkPrimary:'#b2b2b2',
    linkSecondary:'rgb(51, 51, 51)',
    iconPrimary:'#fff',
    iconSecondary:'#111',
    logoTextPrimary:'#fff',
    logoTextSecondary:'#111',
    mobileLinkColor:'#fff',
    buttonBgSecondary:'rgb(7, 90, 255)',
    buttonBorderSecondary:'rgb(7, 90, 255)',
    buttonBgPrimary:'transparent',
    buttonBorderPrimary:'rgb(247, 247, 247)'
    


}