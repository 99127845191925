import * as React from "react"

const SupportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 513 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M60.753 348.552h-2.078A58.175 58.175 0 0 1 .5 290.377v-45.202A58.175 58.175 0 0 1 58.675 187H79v-.5C79 88.469 158.47 9 256.5 9 354.531 9 434 88.469 434 186.5v.5h20.325a58.175 58.175 0 0 1 58.175 58.175v45.202a58.175 58.175 0 0 1-58.175 58.175h-28.182A10.143 10.143 0 0 1 416 338.409V186.5C416 98.411 344.59 27 256.5 27 168.411 27 97 98.411 97 186.5v151.909a10.142 10.142 0 0 1-10.142 10.143h-8.104C79.215 405.491 125.51 451.5 182.55 451.5h17.414A42.498 42.498 0 0 1 241.5 418h30c23.472 0 42.5 19.028 42.5 42.5S294.972 503 271.5 503h-30c-20.383 0-37.415-14.349-41.545-33.5H182.55c-66.986 0-121.342-54.075-121.797-120.948zM296 460.5c0-13.531-10.969-24.5-24.5-24.5h-30a24.499 24.499 0 0 0-24.5 24.5c0 13.531 10.969 24.5 24.5 24.5h30c13.531 0 24.5-10.969 24.5-24.5zM79 205H58.675A40.176 40.176 0 0 0 18.5 245.175v45.202a40.176 40.176 0 0 0 40.175 40.175H79zm355 0v125.552h20.325a40.176 40.176 0 0 0 40.175-40.175v-45.202A40.172 40.172 0 0 0 454.325 205z"
      data-original="#000000"
      xmlns="http://www.w3.org/2000/svg"
    />
  </svg>
)

export default SupportIcon
