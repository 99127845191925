import * as React from "react"

const MapIcon = (props) => (
  <svg
    width={59}
    height={59}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <use xlinkHref="#a" />
      </clipPath>
      <path d="M0 0h59v59H0V0Z" id="a" />
    </defs>
    <path d="M0 0h59v59H0V0Z" fill="none" />
    <g clipPath="url(#b)">
      <path
        d="m58.631 56.59-10.37-24.199a1.728 1.728 0 0 0-1.59-1.047h-34.57c-.691 0-1.316.412-1.589 1.047l-10.37 24.2A1.73 1.73 0 0 0 1.73 59h55.312a1.73 1.73 0 0 0 1.59-2.41Z"
        fill="#E0F4FE"
      />
      <path
        d="M57.042 59H29.386V31.344h17.285a1.73 1.73 0 0 1 1.59 1.047l10.37 24.2A1.73 1.73 0 0 1 57.042 59Z"
        fill="#BCDCFE"
      />
      <path
        d="m7.341 49.14-1.446 2.797-1.544 3.606h20.862l2.238-.268-10.835-10.89L7.34 49.14Z"
        fill="#159BE1"
      />
      <path
        d="m51.208 48.57-.55-1.81-5.126-11.96h-6.185l-4.185.076-18.546 9.509 10.835 10.89 2.65.268h3.597l4.136-.116 13.374-6.857Z"
        fill="#5DC983"
      />
      <g fill="#0EAB61">
        <path d="m51.208 48.57-13.374 6.858-4.136.115h-3.596l-.716-.073V37.838l5.776-2.961 2.796-.051 1.39-.025h6.184l5.125 11.959.551 1.81ZM13.24 34.8 8.038 46.943 7.34 49.14l27.82-14.264-3.4-.075H13.24Z" />
        <path d="m35.162 34.877-5.776 2.961v-3.037h2.374l3.402.076Z" />
      </g>
      <path
        d="m51.208 48.57-13.374 6.857 3.381.116h13.206l-2.398-5.597-.815-1.376Z"
        fill="#159BE1"
      />
      <path
        d="m19.512 44.953 10.59 10.59h-4.889l-8.935-8.935-10.373 5.307 2.132-4.973L31.76 34.8h7.587L19.512 44.953Z"
        fill="#FED941"
      />
      <path d="m29.386 54.827.716.716h-.716v-.716Z" fill="#FEB99E" />
      <path
        d="m39.347 34.8-2.07 1.06-7.89 4.039v-3.884l2.373-1.214h7.587ZM52.023 49.946l-10.808 5.597h-7.517l16.96-8.783 1.365 3.186Z"
        fill="#FCBE29"
      />
      <path
        d="M29.386 0C20.936 0 13.83 6.194 13.83 16.94c0 10.892 13.67 26.738 14.252 27.407.688.792 1.92.793 2.609 0 .582-.67 14.252-16.515 14.252-27.408C44.943 6.293 37.706 0 29.386 0Zm0 20.742a5.192 5.192 0 0 1-5.185-5.185 5.192 5.192 0 0 1 5.185-5.186 5.191 5.191 0 0 1 5.186 5.186 5.192 5.192 0 0 1-5.186 5.185Z"
        fill="#EE3616"
        fillRule="evenodd"
      />
      <path
        d="M29.386 0v10.371a5.192 5.192 0 0 1 5.186 5.186 5.192 5.192 0 0 1-5.186 5.185v24.2c.48 0 .96-.199 1.305-.595 5.098-5.861 14.252-18.626 14.252-27.408C44.943 6.293 37.705 0 29.386 0Z"
        fill="#CB0010"
      />
    </g>
  </svg>
)

export default MapIcon
