import * as React from "react"

const ServerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 9.014H8a.986.986 0 1 0 0 1.972h20a.986.986 0 1 0 0-1.972z"
        data-original="#000000"
      />
      <circle cx={52.999} cy={9.999} r={2.999} data-original="#000000" />
      <path
        d="M60 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h56a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4zm2 16c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h56c1.103 0 2 .897 2 2v12zM28 31.014H8a.986.986 0 1 0 0 1.972h20a.986.986 0 1 0 0-1.972z"
        data-original="#000000"
      />
      <circle cx={52.999} cy={31.999} r={2.999} data-original="#000000" />
      <path
        d="M60 22H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h56a4 4 0 0 0 4-4V26a4 4 0 0 0-4-4zm2 16c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V26c0-1.103.897-2 2-2h56c1.103 0 2 .897 2 2v12zM28 53.014H8a.986.986 0 1 0 0 1.972h20a.986.986 0 1 0 0-1.972z"
        data-original="#000000"
      />
      <circle cx={52.999} cy={53.999} r={2.999} data-original="#000000" />
      <path
        d="M60 44H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h56a4 4 0 0 0 4-4V48a4 4 0 0 0-4-4zm2 16c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V48c0-1.103.897-2 2-2h56c1.103 0 2 .897 2 2v12z"
        data-original="#000000"
      />
    </g>
  </svg>
)

export default ServerIcon
