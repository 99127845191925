import React from 'react'
import styled from 'styled-components';
import MapIcon from '../ui/icons/MapIcon';


const LocationContainer = styled.div`




.location-title {
   text-align: center;
   margin: 0rem 2rem;
  margin-bottom: 2rem;
  font-family: "Inter", sans-serif;
    h3 {
      padding-top:.5rem;
      font-size: 2.6rem;
      padding-bottom: .5rem;   
    }
  }
  

 

.mapContainer{
  width: 100%;
  margin: auto;
 
 @media screen and (min-width: 567px){
  width: 50%;
 margin: auto;
 }
}

iframe{
    width:100%;
    height: 500px;
}


@media screen and (min-width: 200px) and (max-width: 1080px){
  .location-title h3 {
    padding-top: .5rem;
        font-size: 2rem;
      
}

.location-title h2 {
    padding-top: 1rem;
        font-size: 1.5rem;   
}

}

`


const LocationSection = () => {
  return (
    <>             
   <LocationContainer>

<div className="location-title">
          <MapIcon/>
          <h3>
            ¿Dónde nos ubicamos?
          </h3>
          <h2 className="">
            Costa Rica, Heredia, Belén.
          </h2>
        </div>

        <div className="mapContainer">
          <iframe title="Adnet Location" className='frame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.3667509436655!2d-84.18755147669093!3d9.986532953190894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fa2adf7ca5ab%3A0x34de75e5c4e729b!2sAdnet%20S.%20A.!5e0!3m2!1ses!2scr!4v1655753508843!5m2!1ses!2scr" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </LocationContainer>
    </>

  )
}

export default LocationSection