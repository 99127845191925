import * as React from "react"

const LineIcon = ({color='#000000', height='512',width='512'}) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill = {color}
  >
    <rect y={11} width={width} height={2} rx={1} />
  </svg>
)

export default LineIcon
