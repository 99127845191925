import React from 'react';
import { Button } from '../ui/button/Button';
import './videoSection.css';
import ScreenVideo from '../../assets/videos/network4.mp4';
function VideoSection() {
  return (
    
    <div className='hero-container'>
      <div className="overscreen"></div>
      <video src={ScreenVideo}lazyload autoPlay loop muted />
     
  
     <div className='hero__details'>
       <h1 className='main__text animate__animated animate__fadeInUp '>SOPORTE TÉCNICO Y MANTENIMIENTO<span className="sc-37230bb8-0 cllZLb"> A </span>LA MEDIDA</h1>
     </div>

      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          address='plans'
        >
          VER PLANES
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          address='services'
        >
          SERVICIOS
        </Button>
      </div>
    </div>
   
    
    
    
  );
}

export default VideoSection;
