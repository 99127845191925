
import { useReducer } from "react"
import { AuthContext } from "./auth/authContext"
import { authReducer } from "./auth/authReducer"
import AppRouter from "./routers/AppRouter"

const init = ()=>{
  return{
    
  }
}

export const HeroesApp = () => {

  const [state,dispatch] = useReducer(authReducer,{},init);

  return (
    <AuthContext.Provider
    value={{
      hola: "hola",
      name:''
    }}>
      <AppRouter/>
    </AuthContext.Provider>
      
   
  )
}
