import React from "react";
import styled from "styled-components";
export default function Button({ text, buttonStyle }) {

  const STYLES = ['btn--blue', 'btn--outlined'];

  const checkButtonStyle = STYLES.includes(buttonStyle)
  ? buttonStyle
  : STYLES[0];


  return (
    <Div>
      <button className={` ${checkButtonStyle}`}>{text}</button>
    </Div>
  );
}

const Div = styled.div`
  button {
    border-radius: 4rem;
    padding: 0.8rem 2rem;
    border: none;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    .btn--outlined {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
  }
  .btn--blue {
    background-color: #2d69fd;
    color: white;
  }
`;
