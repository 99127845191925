import * as React from "react"

const AdnetIcon = ({letterscolor = "white"}) => (
     <svg
    width={340}
    height={46}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
   
  >
    <defs>
      <clipPath id="Adnet">
        <use xlinkHref="#AdnetA" />
      </clipPath>
      <path d="M0 0h348v46H0V0Z" id="AdnetA" />
    </defs>
    <path d="M0 0h348v46H0V0Z" fill="none" />
    <g clipPath="url(#Adnet)" fillRule="evenodd">
      <path
        d="M26.812 3.726 18.972 17.5h7.562l3.873-6.588 8.285 15.712H21.173l3.685-6.27h-7.512L4.84 42.335h8.523l4.379-8.484H42.48l4.025 8.483h7.102V36.47L34.193 3.791l-7.381-.065ZM52.186 7.98l2.368 2.514-.002 17.854h7.576v-11.57l-1.893-2.932h18.466S88.997 12.169 90.063 23.9c1.066 11.73-11.836 11.73-11.836 11.73H60.235l1.893-3.352v-1.077h-7.576l.002 11.132h26.04s17.648-.616 17.045-18.852C97.047 5.572 76.806 7.98 76.806 7.98h-24.62Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="m96.218 3.372 3.314 3.351v35.611h8.05V15.94l30.775 25.977h5.681V3.372h-7.574v26.813L105.215 3.372h-8.997ZM144.985 10.075v31.842h33.616v-6.703h-26.04v-7.542h15.152v-5.865h-15.625v-6.703h22.253v-5.029h-29.356Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="M175.094 10.075v5.029h14.205v13.244h7.576V15.104h15.623v-5.029h-37.404Zm14.205 21.127v5.687l-3.315 5.028h10.891V31.202h-7.576ZM243.444 21.152h13.029v3.676h-13.029v-3.676Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
      <path
        d="M268.688 25.913h13.031v3.676h-13.031v-3.676ZM276.018 18.213h13.029v3.676h-13.029v-3.676Z"
        fill="#666"
      />
      <path
        d="M325.694 18.213h18.729v3.676h-18.729v-3.676ZM302.078 10.86h13.03v3.676h-13.03V10.86ZM302.078 24.828h23.616v3.677h-23.616v-3.677Z"
        fill={(letterscolor === "white") ? "#FFF" : "#111"}
      />
    </g>
  </svg>
)

export default AdnetIcon
