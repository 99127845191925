import * as React from "react"

const HostingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 74 74"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M49.982 26.844A1 1 0 0 1 49 26.03C46.98 15.4 42.157 8.25 37 8.25s-9.98 7.15-12 17.78a1 1 0 0 1-1.965-.373C25.274 13.868 30.756 6.25 37 6.25s11.726 7.618 13.966 19.407a1 1 0 0 1-.984 1.187ZM37 67.75c-6.244 0-11.726-7.618-13.966-19.407A1 1 0 1 1 25 47.97c2.02 10.63 6.843 17.78 12 17.78s9.98-7.15 12-17.78a1 1 0 0 1 1.965.373C48.726 60.132 43.244 67.75 37 67.75Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M37 67.75a1 1 0 0 1-1-1V48.156a1 1 0 0 1 2 0V66.75a1 1 0 0 1-1 1ZM37 26.844a1 1 0 0 1-1-1V7.25a1 1 0 0 1 2 0v18.594a1 1 0 0 1-1 1ZM62.239 53.74a1 1 0 0 1-.845-1.532 28.621 28.621 0 0 0 2.259-4.424 1 1 0 0 1 1.854.753 30.654 30.654 0 0 1-2.42 4.735 1 1 0 0 1-.848.468Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M37 67.75A30.621 30.621 0 0 1 8.493 48.535a1 1 0 0 1 1.854-.75 28.757 28.757 0 0 0 38.1 15.588 1 1 0 1 1 .8 1.834A30.5 30.5 0 0 1 37 67.75ZM54.931 61.74a1 1 0 0 1-.6-1.8 27.709 27.709 0 0 0 2.24-1.882 1 1 0 0 1 1.364 1.462 29.776 29.776 0 0 1-2.4 2.018.992.992 0 0 1-.604.202ZM64.582 26.844a1 1 0 0 1-.927-.625 28.754 28.754 0 0 0-53.31 0 1 1 0 1 1-1.854-.75 30.754 30.754 0 0 1 57.018 0 1 1 0 0 1-.552 1.3 1.012 1.012 0 0 1-.375.075Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M68.875 49.156H5.125A3.129 3.129 0 0 1 2 46.031V27.969a3.129 3.129 0 0 1 3.125-3.125h63.75A3.129 3.129 0 0 1 72 27.969v18.062a3.129 3.129 0 0 1-3.125 3.125ZM5.125 26.844A1.127 1.127 0 0 0 4 27.969v18.062a1.127 1.127 0 0 0 1.125 1.125h63.75A1.127 1.127 0 0 0 70 46.031V27.969a1.127 1.127 0 0 0-1.125-1.125Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m26.685 31.93-2.809 10.14H22.59l-2.435-8.812-2.442 8.812h-1.287l-2.8-10.14h1.411l2.027 8.259 2.283-8.259h1.611l2.283 8.259 2.027-8.259Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M23.971 42.2H22.5l-2.34-8.468-2.352 8.468h-1.476l-2.871-10.39h1.673l1.935 7.889 2.182-7.889h1.8l2.181 7.889 1.936-7.889h1.68Zm-1.286-.25h1.1l2.74-9.89h-1.161l-2.117 8.629-2.385-8.629H19.44l-2.384 8.629-2.118-8.629h-1.149l2.732 9.89h1.1l2.537-9.156ZM43.53 31.93l-2.809 10.14h-1.286L37 33.258l-2.442 8.812h-1.286l-2.8-10.14h1.411l2.027 8.259 2.283-8.259H37.8l2.283 8.259 2.027-8.259Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M40.816 42.2H39.34L37 33.727 34.653 42.2h-1.476l-2.871-10.39h1.673l1.936 7.889 2.185-7.894h1.8l2.181 7.889 1.937-7.889h1.679Zm-1.287-.25h1.1l2.739-9.89H42.21l-2.118 8.629-2.384-8.629h-1.423L33.9 40.684l-2.118-8.629h-1.148l2.732 9.89h1.1L37 32.789ZM60.375 31.93l-2.808 10.14H56.28l-2.435-8.812L51.4 42.07h-1.283l-2.8-10.14h1.411l2.027 8.259 2.283-8.259h1.612l2.282 8.259 2.027-8.259Z"
      data-original="#000000"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M57.661 42.2h-1.475l-2.341-8.468L51.5 42.2h-1.478l-2.871-10.39h1.673l1.936 7.889 2.181-7.889h1.8l2.182 7.889 1.935-7.889h1.68Zm-1.286-.25h1.1l2.739-9.89h-1.159l-2.117 8.629-2.385-8.629h-1.422l-2.385 8.629-2.117-8.629h-1.15l2.733 9.89h1.1l2.536-9.156Z"
      data-original="#000000"
    />
  </svg>
)

export default HostingIcon
