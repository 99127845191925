import { Link } from 'react-router-dom'
import styled from 'styled-components';

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-height:50vh;
padding: 4rem 0;


.card{
    position: relative;
    width:350px;
    height: 190px;
    /* height: 450px; */
    background: #fff;
    border-radius: 0px 0px 20px 20px;
    box-shadow:0 35px 80px rgba(0,0,0,0.15);
    transition: 0.5s;
    &:hover{
   height: 450px;  
     
   .imgContainer{
    width: 250px;
    height: 250px;
      } 

.details{
    transform: translateY(0px);

    @media screen and (max-width: 900px){
     padding: 34px;
    }
}

    }

    .content{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow: hidden;
    }

    .details{
      padding:40px;
      text-align: center;
      width: 100%;
      transition: 0.5s;
      transform: translateY(140px);

      h2{
          font-size: 1.25em;
          font-weight: 600;
          color: #555;
          line-height: 1.2em;

       span{
          font-size : 0.75em;
          font-weight: 500;
          opacity: 0.5;
       }   
      }

     .data{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         margin: 20px 0;

         h3{
             font-size: 1em;
             color: #555;
             line-height:1.2em;
         }
     } 

     .moreButton{
         padding: 10px 10px;
         border: none;
         outline: none;
         border-radius: 5px;
         font-size: 1em;
         font-weight: 500;
         text-decoration: none;
         background-color: rgb(7,90,255);
         color: #fff;
         cursor: pointer;
     }
    }


}

.imgContainer{
position: absolute;
left: 50%;
top: -50px;
transform: translateX(-50%);
width: 150px;
height: 150px;
background: #fff;
box-shadow: 0 15px 50px rgba(0,0,0,0.35);
border-radius: 20px;
overflow: hidden;
img{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 object-fit:cover;
 transition: 0.5s;
}
}


`;


export const HeroCard = ({
    id,
    superhero,
    publisher,
    alter_ego,
    first_appearance,
    characters,
}) => {

    const imagePath = `/assets/${id}.jpg`;

    return (


        <Container>
            <div className="card animate__animated animate__fadeIn">
                <div className="imgContainer">
                    <img src={imagePath} alt={superhero}/>
                </div>
                <div className="content">
                    <div className="details">
                        <h2>{superhero}<br /><span>{alter_ego}</span></h2>
                        <div className="data">
                            <h3><span>{first_appearance}</span></h3>

                            <Link className="moreButton" to={`/hero/${id}`}>
                                Más...
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Container>



    )
}