import styled from "styled-components";

import { ImFacebook } from "react-icons/im";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";

const MainContainer = styled.div`
  footer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 100px;
    background: #111;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: "Inter", sans-serif;
  }

  footer .footer__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  footer .footer__container .sec {
    color: aliceblue;
    margin-right: 30px;
  }

  footer .footer__container .sec.aboutus {
    width: 40%;
  }

  footer .footer__container h2 {
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
  }

  footer .footer__container h2::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background: rgb(7, 90, 255);
  }

  footer p {
    color: #999;
  }

  .sci {
    margin-top: 20px;
    display: flex;
  }

  .sci li {
    list-style: none;
  }

  .sci li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    text-decoration: none;
    border-radius: 4px;
  }

  .sci li a:hover {
    background: rgb(7, 90, 255);
  }

  .sci li a .fa {
    color: #fff;
    font-size: 20px;
  }

  .quickLinks {
    position: relative;
    width: 25%;
  }

  .quickLinks ul li {
    list-style: none;
  }

  .quickLinks ul li a {
    color: #999;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
  }

  .quickLinks ul li a:hover {
    color: #fff;
  }

  .contact {
    width: calc(35% - 60px);
    margin-right: 0 !important;
  }

  .contact .info {
    position: relative;
  }

  .contact .info li {
    display: flex;
    margin-bottom: 16px;
  }

  .contact .info li span:nth-child(1) {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
  }

  .contact .info li span {
    color: #999;
    font-size: 20px;
    margin-right: 10px;
  }

  .contact .info li a {
    color: #999;
    text-decoration: none;
  }

  .contact .info li a:hover {
    color: #fff;
  }

  .copyright {
    width: 100%;
    background: #181818;
    padding: 8px 100px;
    text-align: center;
    color: #999;
  }

  @media (max-width: 991px) {
    footer {
      padding: 40px;
    }

    footer .footer__container {
      flex-direction: column;
      align-content: center;
    }

    footer .footer__container .sec {
      margin-right: 0;
      margin-bottom: 40px;
    }
    footer .footer__container .sec.aboutus,
    .quickLinks,
    .contact {
      width: 100%;
    }

    .copyright {
      padding: 8px 40px;
    }
  }

  .footer__socials-icon {
    color: #fff;
    width: 1rem;
    height: 1rem;
  }
`;

const FooterSection = () => {
  return (
    <MainContainer>
      <footer>
        <div className="footer__container">
          <div className="sec aboutus">
            <h2>Sobre Nosotros</h2>
            <p>
              Somos una empresa de especialistas en Soporte Técnico en Costa Rica desde el año
              2001. Con más de 20 años de experiencia Iniciamos resolviendo 
              problemas informáticos, luego creamos
              planes de mantenimiento específicos para cada lugar y hoy hacemos
              soluciones a la medida de cada empresa incluso a nivel
              internacional, siempre revisando hasta el mínimo detalle. De esa
              manera podemos ofrecer servicios de calidad, que aporta gran
              valor a su empresa y con precios sin competencia. Si su empresa
              está en Costa Rica o en cualquier otro país, ¡contáctenos!. Nuestro equipo 
              estará encantado de atenderle.
            </p>
            <ul className="sci">
              <li>
                <a
                  href="https://www.facebook.com/adnetcr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImFacebook className="footer__socials-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/adnet_cr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram className="footer__socials-icon" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                  <BsWhatsapp className="footer__socials-icon" />
                </a>
              </li>
            </ul>
          </div>

          <div className="sec quickLinks">
            <h2>Enlaces</h2>
            <ul>
              <li>
                <a href="#">Sobre Nosotros</a>
              </li>
              <li>
                <a href="#">Servicios</a>
              </li>
              <li>
                <a href="#">Planes</a>
              </li>
              <li>
                <a href="#">Contacto</a>
              </li>
            
            </ul>
          </div>

          <div className="sec contact">
            <h2>Contacto</h2>
            <ul className="info">
              <li>
                <span>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </span>
                <span>
                  Belén
                  <br />
                  Heredia
                  <br />
                  Costa Rica
                </span>
              </li>

              <li>
                <span>
                  {" "}
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>
                <p>
                  <a href="tel:50622394970">+(506)2239-4970</a>
                  <br />
                  <a href="tel:50662619838">+(506)8812-1261</a>
                </p>
              </li>

              <li>
                <span>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>

                <div className="d-flex flex-column">
                  <a href="mailto:soporte@adnetcr.com">soporte@adnetcr.com</a>
                  <a href="mailto:ventas@adnetcr.com">ventas@adnetcr.com</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright © 2022 Adnetcr.com Soporte Técnico en Costa Rica</p>
          <p> Todos los derechos reservados.</p>
        </div>
      </footer>
    </MainContainer>
  );
};

export default FooterSection;
