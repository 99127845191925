
import StyledNav from "../ui/StyledNav";
import ServiceItem from "./ServiceItem";
import "./servicesScreen.css";
import support1 from "../../assets/services/support1.jpg";
import support2 from "../../assets/services/support2.jpg";

import shortid from 'shortid';

const servicesData=[
  {
    id:1,
   title:"SOPORTE TECNICO Y MANTENIMIENTO PREVENTIVO",
   description:"Nuestros especialistas le brindan acompañamiento en:",
   links:[
    {
      id:1,
     name:"MANTENIMIENTO PREVENTIVO",
     isLink:true,
     address:'/marvel',
    },
    {
      id:2,
      name:"SOPORTE TÉCNICO",
      isLink:false,
      address:'',
    },
    {
      id:3,
      name:"REPARACIÓN DE EQUIPOS",
      isLink:false,
      address:'',
    },
    {
      id:4,
      name:"INSTALACIÓN DE SOFTWARE",
      isLink:false,
      address:'',
    },
    {
      id:5,
      name:"CONFIGURACIÓN DE HARDWARE",
      isLink:false,
      address:'',
    }
],
images:[
  support1, support2
]
  },
  {
    id:2,
    title:"SOFTWARE Y LICENCIAMIENTO",
    description:"Suministro, implementación y  gestión de licenciamiento original de software.",
    links:[
    {
      id:6,
     name:"Microsoft 365",
     isLink:true,
     address:'',
    },
    {
      id:7,
      name:"Google Workspace",
      isLink:false,
      address:'',
    },
    {
      id:8,
      name:"Eset Antivirus",
      isLink:false,
      address:'',
    },
    {
      id:9,
      name:"CCleaner",
      isLink:false,
      address:'',
    }
],
images:[
  support1, support2
]
  },
  {
    id:3,
    title:"EQUIPO DE COMPUTO Y PERIFÉRICOS",
    description:"Distribuidores autorizados de equipo de cómputo de alta gama  de las marcas más reconocidas del mercado.",
  links:[
    {
      id:10,
     name:"LENOVO",
     isLink:true,
      address:'',
    },
    {
      id:11,
      name:"DELL",
      isLink:false,
      address:'',
    },
    {
      id:12,
      name:"APPLE",
      isLink:false,
      address:'',
    },
    {
      id:13,
      name:"TOSHIBA",
      isLink:false,
      address:'',
    }
],
images:[
  support1, support2
]
  },

  {
    id:4,
    title:"REDES",
    description:"Hacemos el diseño, la  instalación y la configuración del cableado estructurado, y la red inalámbrica de su empresa.",
    links:[
      {
        id:14,
       name:"DATACENTER",
       isLink:true,
       address:'/',
      },
      {
        id:15,
        name:"ROUTER/FIREWALL",
        isLink:false,
        address:'/marvel',
      },
      {
        id:16,
        name:"SWITCHES",
        isLink:false,
        address:'',
      },
      {
        id:17,
        name:"ACCESS POINTS",
        isLink:false,
        address:'',
      }
  ],
  images:[
    support1, support2
  ]
  }
]


const Services = () => {
  return (
    <>
      <StyledNav transparent={'false'}/>
    <div className="services__container content__section">

    {
      servicesData.map((service) => { 
        return <ServiceItem data={service} key={shortid.generate()}/>
      })
    }

    </div>
    </>
    
  )
}

export default Services