import React from 'react'
import AdvantageScreen from '../advantages/AdvantageScreen'
import StyledNav from '../ui/StyledNav'
import FooterSection from './FooterSection'
import HabilitiesSection from './HabilitiesSection'
import LocationSection from './LocationSection'
import ServicesSectionRoom from './ServicesSectionRoom'
import VideoSection from './VideoSection'

const MainScreen = () => {
  return (
    <>
    <StyledNav transparent={'true'}/>
    <VideoSection/>
    <ServicesSectionRoom/>
    <HabilitiesSection/>

    <AdvantageScreen/>
    <LocationSection/>
    <FooterSection/>
    </>
  )
}

export default MainScreen